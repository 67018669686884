import React from "react";
import {FaHome, FaCar, FaMapMarkedAlt, FaInfoCircle, FaAddressCard} from "react-icons/fa";
import {MdHome, MdDirectionsCar, MdPublic, MdHotel} from "react-icons/md";

const BottomNavData = [
    {
        title: "Home",
        path: "/",
        icon: <MdHome size={28}/>
    },
    {
        title: "Rentals",
        path: "/rentals",
        icon: <MdDirectionsCar size={28}/>
    },
    {
        title: "Tours",
        path: "/tours",
        icon: <MdPublic size={28}/>
    },
    {
        title: "Hotels",
        path: "/hotels",
        icon: <MdHotel size={28}/>
    }
];

const SidebarData = [
    {
        title: "Home",
        path: "/",
        icon: <FaHome size={20}/>
    },
    {
        title: "Rentals",
        path: "/rentals",
        icon: <FaCar size={20}/>
    },
    {
        title: "Tours",
        path: "/tours",
        icon: <FaMapMarkedAlt size={20}/>
    },
    {
        title: "Contact",
        path: "/partner",
        icon: <FaAddressCard size={20}/>
    }
];

export {BottomNavData, SidebarData};