import React, { Suspense } from "react";
import TopNavBar from "./components/TopNavBar";
import BottomNav from "./components/BottomNavBar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ImSpinner9 } from "react-icons/im";

const Home = React.lazy(() => import("./pages/Home"));
const Rentals = React.lazy(() => import("./pages/Rentals"));
const Tours = React.lazy(() => import("./pages/Tours"));
const Error404 = React.lazy(() => import("./pages/Error404"));
const RentalId = React.lazy(() => import("./pages/RentalId"));
const TourId = React.lazy(() => import("./pages/TourId"));
const Hotels = React.lazy(() => import("./pages/Hotels"));
const HotelId = React.lazy(() => import("./pages/HotelId"));
const Partner = React.lazy(() => import("./pages/Partner"));
const EventZiro = React.lazy(() => import("./pages/events/ziro"));
const EventDambuk = React.lazy(() => import("./pages/events/dambuk"));

function Loading() {
  return (
    <div className="h-screen bg-white text-black dark:bg-black dark:text-white flex items-center justify-center dark-transit">
      <ImSpinner9 className="animate-spin" size={40} />
    </div>
  );
}

function App() {
  return (
    <Router>
      <TopNavBar />
      <Routes>
        <Route
          path=""
          element={
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="rentals"
          element={
            <Suspense fallback={<Loading />}>
              <Rentals />
            </Suspense>
          }
        />
        <Route
          path="rentals/:rentalid"
          element={
            <Suspense fallback={<Loading />}>
              <RentalId />
            </Suspense>
          }
        />
        <Route
          path="tours"
          element={
            <Suspense fallback={<Loading />}>
              <Tours />
            </Suspense>
          }
        />
        <Route
          path="tours/:tourid"
          element={
            <Suspense fallback={<Loading />}>
              <TourId />
            </Suspense>
          }
        />
        <Route
          path="partner"
          element={
            <Suspense fallback={<Loading />}>
              <Partner />
            </Suspense>
          }
        />
        {/* <Route
          path="dambuk"
          element={
            <Suspense fallback={<Loading />}>
              <EventDambuk />
            </Suspense>
          }
        />
        <Route
          path="events/1"
          element={
            <Suspense fallback={<Loading />}>
              <EventDambuk />
            </Suspense>
          }
        /> */}
        <Route
          path="ziro"
          element={
            <Suspense fallback={<Loading />}>
              <EventZiro />
            </Suspense>
          }
        />
        <Route
          path="events/2"
          element={
            <Suspense fallback={<Loading />}>
              <EventZiro />
            </Suspense>
          }
        />
        <Route
          path="hotels"
          element={
            <Suspense fallback={<Loading />}>
              <Hotels />
            </Suspense>
          }
        />
        <Route
          path="hotels/:hotelid"
          element={
            <Suspense fallback={<Loading />}>
              <HotelId />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<Loading />}>
              <Error404 />
            </Suspense>
          }
        />
      </Routes>
      <BottomNav />
    </Router>
  );
}

export default App;
