import { BottomNavData } from "./data/NavBarData.js";
import { NavLink } from "react-router-dom";

const BottomNav = () => {
  return (
    <div className="md:hidden bottom-navbar no-highlights">
      {BottomNavData.map((item, index) => {
        return (
          <NavLink
            key={index}
            to={item.path}
            className={({ isActive }) =>
              isActive
                ? "bottom-nav-cards text-blue-600 dark:text-blue-400 "
                : "bottom-nav-cards"
            }
          >
            <div className="pb-2">
              <span className="flex justify-center pb-1 pt-2">{item.icon}</span>
              <p className="flex justify-center font-poppins">{item.title}</p>
            </div>
          </NavLink>
        );
      })}
    </div>
  );
};

export default BottomNav;
