import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { SidebarData } from "./data/NavBarData.js";
import { FaTimes, FaBars, FaMoon, FaSun, FaHandshake } from "react-icons/fa";
import useDarkMode from "../hooks/DarkMode.js";

const ThemeIcon = () => {
  const [darkTheme, setDarkTheme] = useDarkMode();
  const handleMode = () => setDarkTheme(!darkTheme);
  return (
    <span onClick={handleMode}>
      {darkTheme ? <FaSun size="22" /> : <FaMoon size="22" />}
    </span>
  );
};

function TopNavBar() {
  const [sidebar, setSidebar] = useState(false);
  const toggleSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <div className="md:hidden top-navbar no-highlights z-40">
        <span className="my-auto mx-4">
          {" "}
          <FaBars size={22} onClick={toggleSidebar} />
        </span>
        <p className="text-xl font-bold font-poppins my-4">ChalloNorthEast</p>
        <span className="my-auto mx-4">
          {" "}
          <ThemeIcon />
        </span>
      </div>
      <span
        className={sidebar ? "overlay" : "overlay opacity-0 hidden"}
        onClick={toggleSidebar}
      ></span>
      <div className={sidebar ? "navbar no-highlights" : "navbar-hidden"}>
        <ul className="h-full mx-2 mt-2" onClick={toggleSidebar}>
          <li className="m-4 mb-10 flex justify-between font-bold text-xl">
            <span className="text-2xl">Menu</span>
            <span>
              <FaTimes size={28} />
            </span>
          </li>
          {SidebarData.map((item, index) => {
            return (
              <li key={index} className="mr-3">
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    isActive ? "sidebar-items-active " : "sidebar-items"
                  }
                >
                  <span className="mx-2 my-auto">{item.icon}</span>
                  <p className="my-auto">{item.title}</p>
                </NavLink>
              </li>
            );
          })}
        </ul>
        <div className="flex justify-center" onClick={toggleSidebar}>
          <NavLink
            to="partner"
            className="flex -mt-10 sidebar-partner justify-center"
          >
            <span className="mx-2 my-auto">
              <FaHandshake size={20} />
            </span>
            <p className="my-auto">Partner with us</p>
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default TopNavBar;
